import * as React from 'react'
import { Link } from 'gatsby'
let Scroll = require('react-scroll');
let scroller = Scroll.scroller;
var Element = Scroll.Element;

const P = styled.p`
font-family: ChaparralPro-Light, serif;
font-weight: normal;
font-size: 1.1em;
letter-spacing: 0.1em;
color: #333;
padding-bottom: 2.5em;
line-height: 1.5em;`
import Page from '../components/Page'
import Container from '../components/Container'
import IndexLayout from '../layouts'
import styled from "@emotion/styled";

class Success extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: true
    }
  }
  componentDidMount() {
    scroller.scrollTo('header', {
      duration: 1500,
      smooth: true,
    })
  }


  render() {
    return (
  <IndexLayout>
    <Page>
      <Container>
        <Element id={'header'}/>
        <h1>Vilhelm7 sagt vielen Dank!</h1>
        <P>
          Wir werden Ihre Anfrage schnellstmöglich bearbeiten und uns in Kürze bei Ihnen melden.


        </P>
      </Container>
    </Page>
  </IndexLayout>
    )
  }
}
export default Success
